import NestedForm from '@stimulus-components/rails-nested-form'
import { connectControllers } from "~/modules/common";

export default class extends NestedForm {
    connect() {
        super.connect();
    }
    add(event) {
        super.add(event);
        connectControllers(document.body, 'react-single-select');
    }
}
