import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import { zhCN } from 'date-fns/locale/zh-CN';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { defaultStaticRanges } from '@/modules/defaultRanges';
import useStore from '@/modules/store';
import { format, parse } from "date-fns";

const DateRangePickerComponent = ({ id, onDateRangeChange, defaultDateRange }) => {
    if(!defaultDateRange) {
        defaultDateRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        };
    }

    const [dateRange, setDateRange] = useState(defaultDateRange);
    const setDateRangeValue = useStore(state => state.setDateRangeValue);

    useEffect(() => {
        const daterangeInput = document.getElementById('daterange_input');
        if (daterangeInput && daterangeInput.value) {
            const parsedDateRange = parseDateRange(daterangeInput.value);
            setDateRange(parsedDateRange);
        }
    }, []);

    const handleChange = dateRange => {
        setDateRange(dateRange.selection);
        if (onDateRangeChange) {
            onDateRangeChange(dateRange);
        }
        setDateRangeValue(id, dateRange ? dateRange.selection : null );
        updateInputValue(dateRange.selection);
    };

    const updateInputValue = (selection) => {
        const daterangeInput = document.getElementById('daterange_input');
        if (daterangeInput && selection) {
            daterangeInput.value = formatDateRange(selection.startDate, selection.endDate);
        }
    };

    return (
        <DateRangePicker
            ranges={[dateRange]}
            onChange={handleChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            calendarFocus="backwards"
            preventSnapRefocus={true}
            dateDisplayFormat="yyyy-MM-dd"
            locale={zhCN}
            weekStartsOn={1}
            staticRanges={defaultStaticRanges}
            inputRanges={[]}
        />
    );
}

const formatDateRange = (startDate, endDate) => {
    if (startDate.getTime() === endDate.getTime()) {
        return format(startDate, 'yyyy/MM/dd', { locale: zhCN });
    }
    return `${format(startDate, 'yyyy/MM/dd', { locale: zhCN })} - ${format(endDate, 'yyyy/MM/dd', { locale: zhCN })}`;
};

const parseDateRange = (dateRangeString) => {
    const dates = dateRangeString.split(' - ').map(date => parse(date, 'yyyy/MM/dd', new Date(), { locale: zhCN }));
    return {
        startDate: dates[0],
        endDate: dates[1] || dates[0],
        key: 'selection'
    };
};

export default DateRangePickerComponent;
