import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default/icons.min.js'
import 'tinymce/themes/silver/theme.min.js'
import 'tinymce/models/dom/model.min.js'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/table'
// import 'tinymce/plugins/wordcount'
import 'tinymce-i18n/langs/zh_CN'
import 'tinymce/skins/ui/oxide/skin.css';
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['input', 'form'];

    initialize() {
        this.handleContentChange = this.handleContentChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.defaults = {
            language: 'zh_CN',
            license_key: 'gpl',
            plugins: 'importcss autosave fullscreen image link table',
            menubar: false,
            statusbar: false,
            toolbar:
                'bold underline alignleft aligncenter | blocks fontfamily fontsize | table image | removeformat fullscreen',
            toolbar_sticky: true,
            images_upload_url: '/tinymce_assets',
            convert_urls: false,
            file_picker_types: 'image media',
            skin: false,
            content_css: '/static/tinymce/content.css',
            font_css: '/static/chinese_fonts.css',
            font_size_formats: '10pt 12pt 14pt 16pt 18pt 22pt 24pt 26pt 36pt 42pt',
            font_family_formats: "方正小标宋='方正小标宋简体',FZXiaoBiaoSong-B05; 方正仿宋='方正仿宋简体',FZFangSong-Z02; 方正楷体='方正楷体简体',FZKai-Z03; 方正黑体='方正黑体简体',FZHei-B01; Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;Georgia=georgia,palatino;Tahoma=tahoma,arial,helvetica,sans-serif;Times New Roman=times new roman,times,serif;Verdana=verdana,geneva",
            block_formats: '一级标题=h1; 二级标题=h2; 三级标题=h3; 正文段落=p; 无格式=pre; 引用=blockquote;',
            importcss_append: true,
            formats: {
                h1: { block: 'h1', attributes: {class: 'my-h1'} },
                h2: { block: 'h2', attributes: {class: 'my-h2'} },
                h3: { block: 'h3', attributes: {class: 'my-h3'} },
                p: { block: 'p', attributes: {class: 'my-p'} },
            },
            style_formats: [
                { title: '标题1', format: 'h1' },
                { title: '标题2', format: 'h2' },
                { title: '标题3', format: 'h3' },
                { title: '段落', format: 'p' },
            ],
            setup: editor => {
                editor.on('change', this.handleContentChange);
            }
        }
    }

    connect() {
        let config = Object.assign({ target: this.inputTarget }, this.defaults)
        tinymce.init(config)

        this.formTarget.addEventListener('submit', this.handleSubmit);
    }

    disconnect() {
        let editor = tinymce.get(this.inputTarget.id);
        if (editor) {
            editor.off('change', this.handleContentChange);
            tinymce.remove(editor, () => {
                this.formTarget.removeEventListener('submit', this.handleSubmit);
            });
        }
    }

    // 处理表单提交
    handleSubmit(event) {
        let content = tinymce.get(this.inputTarget.id).getContent();
        const helperId = this.inputTarget.dataset.helperId;
        const required = this.inputTarget.dataset.required;
        const helperElement = document.getElementById(helperId);
        if (required && content.trim().length === 0) {
            if (helperElement) {
                helperElement.classList.add('block');
            }
            event.preventDefault();
        }
    }

    // 处理内容变化
    handleContentChange() {
        let content = tinymce.get(this.inputTarget.id).getContent();
        const helperId = this.inputTarget.dataset.helperId;
        const required = this.inputTarget.dataset.required;
        const helperElement = document.getElementById(helperId);

        if (required && content.trim().length === 0) {
            if (helperElement) {
                helperElement.classList.add('block');
            }
        } else {
            if (helperElement) {
                helperElement.classList.remove('block');
            }
        }
    }
}
