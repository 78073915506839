import { Controller } from "@hotwired/stimulus"
import useStore from '@/modules/store';

export default class extends Controller {
    static targets = ["filterForm", "searchInput", "clearSearchButton"]

    toggleFilterForm() {
        this.filterFormTarget.classList.toggle('hidden');
    }

    toggleSearchClearButton() {
        if (this.searchInputTarget.value.length > 0) {
            this.clearSearchButtonTarget.classList.remove("hidden");
        } else {
            this.clearSearchButtonTarget.classList.add("hidden");
        }
    }

    clearSearch() {
        this.searchInputTarget.value = "";
        this.clearSearchButtonTarget.classList.add("hidden");
        this.searchInputTarget.form.requestSubmit();
    }

    resetFilterForm() {
        const hiddenInputs = this.filterFormTarget.querySelectorAll('input[type="hidden"]:not(#page)');
        hiddenInputs.forEach(input => {
            input.value = "";
        });

        const daterangeInput = document.getElementById("daterange_input");
        if(daterangeInput){
            daterangeInput.value = "";
        }

        const { resetSelects } = useStore.getState();
        resetSelects();
    }
}
