import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["list", "dropdown", "dropdownButton"];

    connect() {
        this.loadOrgTypes();
    }

    loadOrgTypes() {
        fetch('/api/v1/org_types')
            .then(response => response.json())
            .then(orgTypes => this.displayOrgTypes(orgTypes))
            .then(() => this.setDropdownState())
            .then(() => this.setCheckboxState())
            .catch(error => console.log(error));
    }

    setCheckboxState() {
        const params = new URLSearchParams(window.location.search);
        const selectedOrgTypeIds = params.get('org_type_ids') ? params.get('org_type_ids').split(',') : [];

        if (selectedOrgTypeIds.length > 0) {
            // 设置复选框的状态
            this.listTarget.querySelectorAll('.org-type-checkbox').forEach(checkbox => {
                checkbox.checked = selectedOrgTypeIds.includes(checkbox.value);
            });
        }
    }

    setDropdownState() {
        const params = new URLSearchParams(window.location.search);
        const dropdownOpen = params.get('dropdown_open');
        if (dropdownOpen === 'true') {
            this.dropdownButtonTarget.click();
        }
    }

    displayOrgTypes(orgTypes) {
        const params = new URLSearchParams(window.location.search);
        const filtered = params.get('filtered') === 'true';
        const selectedOrgTypeIds = params.get('org_type_ids') ? params.get('org_type_ids').split(',') : orgTypes.map(orgType => orgType.id.toString());
        const items = orgTypes.map(orgType =>
            `<li class="flex items-center">
            <input id="org-type-checkbox-${orgType.id}" type="checkbox" value="${orgType.id}" 
                class="org-type-checkbox w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" 
                data-action="change->org-types#filterOrganizations"
                ${!filtered && selectedOrgTypeIds.includes(orgType.id.toString()) ? 'checked' : ''}>
            <label for="org-type-checkbox-${orgType.id}" class="ml-2 text-sm font-medium text-gray-900">${orgType.name}</label>
        </li>`
        );
        this.listTarget.innerHTML = items.join('');
    }

    filterOrganizations() {
        const url = new URL(window.location);
        url.searchParams.set('filtered', 'true');

        const selectedOrgTypeIds = Array.from(this.element.querySelectorAll('.org-type-checkbox:checked')).map(checkbox => checkbox.value);
        url.searchParams.set('org_type_ids', selectedOrgTypeIds.join(','));

        const searchInput = document.querySelector('[data-search-target="input"]');
        if (searchInput && searchInput.value) {
            url.searchParams.set('q[name_cont]', searchInput.value);
        }

        // 更新URL以包括下拉列表的状态
        const isDropdownOpen = !this.dropdownTarget.classList.contains('hidden');
        url.searchParams.set('dropdown_open', isDropdownOpen);

        Turbo.visit(url.toString());
    }
}