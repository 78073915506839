import { Dismiss } from "flowbite";

document.addEventListener('click', function(event) {
    var target = event.target;
    if (target.type === 'submit' && target.hasAttribute('data-turbo-confirm')) {
      var message = target.getAttribute('data-turbo-confirm');
      if (!confirm(message)) {
        event.preventDefault();
      } else {
        var actionTypeValue = target.getAttribute('data-action-type');
        var hidden = document.getElementById('action_type');
        if(hidden) {
            hidden.value = actionTypeValue;
        }
      }
    }
  });

let dismissFlashMsg = function() {
    const alerts = document.querySelectorAll("[id^='alert-']");

    alerts.forEach(function(alert) {
        setTimeout(function() {
            const options = {
                transition: 'transition-opacity',
                duration: 1000,
                timing: 'ease-out',
            };

            const dismiss = new Dismiss(alert, null, options, {id: alert.id, override: true});
            dismiss.hide();
        }, 5000);
    });
}

document.addEventListener("DOMContentLoaded", dismissFlashMsg);
document.addEventListener("turbo:load", dismissFlashMsg);
