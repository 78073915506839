import { create } from 'zustand';
import { devtools } from 'zustand/middleware'

const useStore = create(devtools(set => ({
    selects: {},
    dateRanges: {},
    setSelectValue: (id, value, required, invalid = false) =>
        set(state => ({
            selects: {
                ...state.selects,
                [id]: { value, required, invalid }
            }
        })),
    setDateRangeValue: (id, selection ) =>
        set(state => ({
            dateRanges: {
                ...state.dateRanges,
                [id]: { selection }
            }
        })),
    resetSelects: () =>
        set(state => ({
            selects: Object.keys(state.selects).reduce((newSelects, id) => {
                newSelects[id] = { ...state.selects[id], value: '' };
                return newSelects;
            }, {})
        }))
})));

export default useStore;
