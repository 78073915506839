import { Controller } from "@hotwired/stimulus";
import useStore from "@/modules/store";

export default class FormValidationController extends Controller {
    static targets = ['input', 'fileInput', 'errorHelper'];

    submit(event) {
        let isFormValid = true;
        const { selects, setSelectValue } = useStore.getState();

        const isDisplayNone = (element) => {
            while (element && element !== document) {
                if (window.getComputedStyle(element).display === 'none') {
                    return true;
                }
                element = element.parentElement;
            }
            return false;
        };

        Object.entries(selects).forEach(([id, state]) => {
            const element = document.getElementById(id);
            if (!isDisplayNone(element)) {
                if (state.required && (!state.value || state.value.length === 0)) {
                    setSelectValue(id, state.value, state.required, true);
                    isFormValid = false;
                } else {
                    setSelectValue(id, state.value, state.required, false);
                }
            }
        });

        this.inputTargets.forEach(input => {
            if (!isDisplayNone(input)) {
                if (input.required && !input.value) {
                    input.removeAttribute('placeholder');
                    isFormValid = false;
                }
            }
        });

        this.fileInputTargets.forEach(input => {
            if (!isDisplayNone(input)) {
                if (input.required && !(input.files.length > 0)) {
                    input.classList.remove('myinitial');
                    isFormValid = false;
                }
            }
        });

        this.errorHelperTargets.forEach(errorHelper => {
            const associatedFieldId = errorHelper.dataset.associatedField;
            const associatedField = document.getElementById(associatedFieldId);

            if (associatedField && !isDisplayNone(associatedField)) {
                if (!associatedField.value) {
                    errorHelper.classList.add("block");
                    isFormValid = false;
                } else {
                    errorHelper.classList.remove("block");
                }
            } else {
                errorHelper.classList.remove("block");
            }
        });

        if (!isFormValid) {
            event.preventDefault();
        }
    }
}