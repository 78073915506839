import { application } from './application';

import ConfirmController from './confirm_controller';
import ListController from './list_controller';
import FormValidationController from './form_validation_controller';
import HelperMessagesController from './helper_massages_controller';
import OrgSearchController from "./org_search_controller";
import OrgTypesController from './org_types_controller.js';
import ReuploadController from './reupload_controller';
import TinymceController from './tinymce_controller';
import OpinionController from './opinion_controller';
import RailsNestedForm from './rails_nested_form_controller';
import ReactComponentController from './react_component_controller';
import ReactSingleSelect from './react_single_select_controller';
import ReactMultiSelect from './react_multi_select_controller';
import VettingController from './vetting_controller';

application.register("confirm", ConfirmController);
application.register("list", ListController);
application.register("form-validation", FormValidationController);
application.register("helper-messages", HelperMessagesController);
application.register('nested-form', RailsNestedForm);
application.register("org-search", OrgSearchController);
application.register("org-types", OrgTypesController);
application.register("reupload", ReuploadController);
application.register("tinymce", TinymceController);
application.register("opinion", OpinionController);
application.register("vetting", VettingController);
application.register("react-component", ReactComponentController);
application.register("react-single-select", ReactSingleSelect);
application.register("react-multi-select", ReactMultiSelect);