import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
    startOfQuarter,
    endOfQuarter,
    addQuarters,
    startOfYear,
    endOfYear,
    addYears
} from 'date-fns';

const defineds = {
    startOfWeek: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endOfWeek: endOfWeek(new Date(), { weekStartsOn: 1 }),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    // 本季度
    startOfCurrentQuarter: startOfQuarter(new Date()),
    endOfCurrentQuarter: endOfQuarter(new Date()),

    // 上季度
    startOfLastQuarter: startOfQuarter(addQuarters(new Date(), -1)),
    endOfLastQuarter: endOfQuarter(addQuarters(new Date(), -1)),

    // 上半年
    startOfFirstHalf: startOfYear(new Date()),
    endOfFirstHalf: endOfMonth(addMonths(startOfYear(new Date()), 5)),

    // 下半年
    startOfSecondHalf: startOfMonth(addMonths(startOfYear(new Date()), 6)),
    endOfSecondHalf: endOfYear(new Date()),

    // 今年
    startOfCurrentYear: startOfYear(new Date()),
    endOfCurrentYear: endOfYear(new Date()),

    // 上年
    startOfLastYear: startOfYear(addYears(new Date(), -1)),
    endOfLastYear: endOfYear(addYears(new Date(), -1)),
};

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range();
        return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
        );
    },
};

export function createStaticRanges(ranges) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
    {
        label: '今天',
        range: () => ({
            startDate: defineds.startOfToday,
            endDate: defineds.endOfToday,
        }),
    },
    {
        label: '这周',
        range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfWeek,
        }),
    },
    {
        label: '本月',
        range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfMonth,
        }),
    },
    {
        label: '上月',
        range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth,
        }),
    },
    {
        label: '本季度',
        range: () => ({
            startDate: defineds.startOfCurrentQuarter,
            endDate: defineds.endOfCurrentQuarter,
        }),
    },
    {
        label: '上季度',
        range: () => ({
            startDate: defineds.startOfLastQuarter,
            endDate: defineds.endOfLastQuarter,
        }),
    },
    {
        label: '上半年',
        range: () => ({
            startDate: defineds.startOfFirstHalf,
            endDate: defineds.endOfFirstHalf,
        }),
    },
    {
        label: '下半年',
        range: () => ({
            startDate: defineds.startOfSecondHalf,
            endDate: defineds.endOfSecondHalf,
        }),
    },
    {
        label: '今年',
        range: () => ({
            startDate: defineds.startOfCurrentYear,
            endDate: defineds.endOfCurrentYear,
        }),
    },
    {
        label: '上年',
        range: () => ({
            startDate: defineds.startOfLastYear,
            endDate: defineds.endOfLastYear,
        }),
    },
]);

export const defaultInputRanges = [
    {
        label: '天（到今天结束）',
        range(value) {
            return {
                startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
                endDate: defineds.endOfToday,
            };
        },
        getCurrentValue(range) {
            if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
            if (!range.startDate) return '∞';
            return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
        },
    },
    {
        label: '天（从今天开始）',
        range(value) {
            const today = new Date();
            return {
                startDate: today,
                endDate: addDays(today, Math.max(Number(value), 1) - 1),
            };
        },
        getCurrentValue(range) {
            if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
            if (!range.endDate) return '∞';
            return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
        },
    },
];