// Purpose: Import and initialize all the components and controllers

import '@hotwired/turbo-rails'
import '../controllers'
import '../modules/events'

import "flowbite/dist/flowbite.turbo.js"
import { Datepicker } from "flowbite-datepicker";

(function () {
    Datepicker.locales.zhCN = {
        days: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
        daysShort: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        daysMin: ["日", "一", "二", "三", "四", "五", "六"],
        months: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
        monthsShort: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
        today: "今天",
        monthsTitle: "选择月份",
        clear: "清除",
        format: "yyyy-mm-dd",
        titleFormat: "y年mm月",
        weekStart: 1
    }
})();