import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import { getCustomSelectStyles } from '@/modules/common';
import useStore from '@/modules/store';

// 通用多选组件
const GenericMultiSelect = ({ id, onChange, required, apiPath, placeholder }) => {
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const selects = useStore(state => state.selects);
    const isInvalid = useStore(state => state.selects[id]?.invalid);
    const setSelectValue = useStore(state => state.setSelectValue);

    useEffect(() => {
        fetch(`/api/v1/${apiPath}`)
            .then(response => response.json())
            .then(data => {
                const formattedOptions = data.map(item => ({ value: item.id, label: item.name }));
                setOptions(formattedOptions);
            });
    }, [apiPath]);

    useEffect(() => {
        const currentSelectValue = selects[id]?.value;
        if (currentSelectValue && Array.isArray(currentSelectValue) && currentSelectValue.length > 0) {
            const selected = currentSelectValue.map(val =>
                options.find(option => option.value === Number(val))
            ).filter(Boolean);
            setSelectedOptions(selected);
        } else {
            setSelectedOptions([]);
        }
    }, [selects[id]?.value, id, options]);

    const handleChange = selectedOptions => {
        setSelectedOptions(selectedOptions || []);
        if (onChange) {
            onChange(selectedOptions.map(option => option.value).join(','));
        }
        setSelectValue(id,
            selectedOptions.length ? selectedOptions.map(option => option.value) : [],
            required,
            isInvalid && !selectedOptions.length);
    };

    const customStyles = getCustomSelectStyles(required, isInvalid);

    placeholder = placeholder || '请选择...';

    return (
        <Select
            value={selectedOptions}
            onChange={handleChange}
            options={options}
            styles={customStyles}
            isMulti={true}
            className="span-sm-gray"
            placeholder={placeholder}
        />
    );
};

export default GenericMultiSelect;
