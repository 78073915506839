import { Controller } from "@hotwired/stimulus"
import useStore from "~/modules/store";

export default class extends Controller {
    static targets = ["reporterForm"];

    submitReporterForm(event) {
        event.preventDefault(); // 防止表单默认提交行为

        const buttonText = document.getElementById('button-text');
        const loadingText = document.getElementById('loading-text');
        const submitButton = document.getElementById('add_reporter_submit_button');

        buttonText.classList.add('hidden');
        loadingText.classList.remove('hidden');
        submitButton.disabled = true;

        const form = this.reporterFormTarget;
        const formData = new FormData(form);

        fetch(form.action, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
                'Accept': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const componentEl = document.getElementById("ReporterSelect");
                    if(componentEl) {
                        const props = JSON.parse(componentEl.getAttribute('data-react-props'));
                        const hiddenId = props['hiddenId'];
                        const storeId = props['storeId'];
                        const hiddenInput = document.getElementById(hiddenId);
                        const value = { id: data.reporter.id, name: data.reporter.name, isNew: true };
                        const setSelectValue = useStore.getState().setSelectValue;
                        setSelectValue(storeId, value, props['required']);
                        if (hiddenInput) {
                            hiddenInput.value = data.reporter.id;
                        }
                    }

                    this.closeReporterFormModal();
                } else {
                    alert("反映者保存失败，错误原因：" + data.errors.join('，'));
                }
            })
            .catch(error => console.error('Error:', error))
            .finally(() => {
                // 恢复按钮原始状态
                buttonText.classList.remove('hidden');
                loadingText.classList.add('hidden');
                submitButton.disabled = false;
            });
    }

    closeReporterFormModal() {
        const modal = FlowbiteInstances.getInstance('Modal', 'reporter-form-modal');
        modal.hide();
    }
}