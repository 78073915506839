import { Controller } from "@hotwired/stimulus"
import React from "react"
import { createRoot } from "react-dom/client"
import GenericMultiSelect from "@/components/GenericMultiSelect"
import useStore from "@/modules/store";

export default class extends Controller {
    static values = { props: Object }

    connect() {
        this.renderComponent()
    }

    disconnect() {
        if (this.root) {
            this.root.unmount()
        }
    }

    renderComponent() {
        const props = this.propsValue
        const storeId = props.storeId
        const hiddenId = props.hiddenId
        const hiddenInput = document.getElementById(hiddenId)

        if(storeId && storeId.indexOf("NEW_RECORD") !== -1){
            return;
        }

        const handleOptionChange = (ids) => {
            if (hiddenInput) {
                hiddenInput.value = ids;
            }
        };

        this.root = createRoot(this.element)
        this.root.render(
            React.createElement(GenericMultiSelect, {
                id: storeId,
                apiPath: props.apiPath,
                required: props.required,
                placeholder: props.placeholder,
                onChange: handleOptionChange
            })
        )

        this.useHiddenInputValue(hiddenInput, storeId, props.required)
    }

    useHiddenInputValue (hiddenInput, storeId, required) {
        const setSelectValue = useStore.getState().setSelectValue;
        if (hiddenInput && hiddenInput.value) {
            setSelectValue(storeId, hiddenInput.value.split(',').map(Number), required);
        }else {
            setSelectValue(storeId, [], required);
        }
    };
}