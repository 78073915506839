import { Controller } from "@hotwired/stimulus"
import { createRoot } from "react-dom/client";
import React from 'react';
import DateRangePickerComponent from '~/components/DateRangePickerComponent';
import { OverviewChart } from '~/components/OverviewChart';

export default class extends Controller {
    static values = {
        name: String,
        props: Object
    }

    connect() {
        const componentName = this.nameValue;
        const props = this.propsValue;

        let Component;
        switch(componentName) {
            case 'DateRangePickerComponent':
                Component = DateRangePickerComponent;
                break;
            case 'OverviewChart':
                Component = OverviewChart;
                break;
            default:
                console.error(`Unknown component: ${componentName}`);
                return;
        }

        this.root = createRoot(this.element);
        this.root.render(React.createElement(Component, props));
    }

    disconnect() {
        if (this.root) {
            this.root.unmount()
        }
    }
}