import { application } from '~/controllers/application';

const getCustomSelectStyles = (isRequired, isInvalid) => ({
    control: (provided, state) => ({
        ...provided,
        borderColor: isRequired && isInvalid && !state.isFocused ? '#ef4444' : provided.borderColor,
        boxShadow: isRequired && isInvalid && !state.isFocused ? '0 0 0 1px #fef2f2' : provided.boxShadow,
        '&:hover': {
            borderColor: isRequired && isInvalid ? 'red' : provided.borderColor,
        },
    }),
    input: (base) => ({
        ...base,
        'input:focus': {
            boxShadow: 'none',
        }
    })
});

const connectControllers = (container, controllerName) => {
    let elements = container.querySelectorAll(`[data-controller=${controllerName}]`);
    for(const element of elements){
        const controller = application.getControllerForElementAndIdentifier(element, controllerName);
        if (controller) {
            controller.connect()
        } else {
            console.log('Controller not found');
        }
    }
};

export { getCustomSelectStyles, connectControllers };