import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.element.querySelectorAll('input[type="text"][required]').forEach(input => {
            input.addEventListener('blur', this.removePlaceholder);
        });

        // 监听隐藏字段的变化
        this.element.querySelectorAll('input[type="hidden"]').forEach(hiddenInput => {
            // 使用 MutationObserver 监听 value 的变化
            const observer = new MutationObserver(() => {
                this.toggleHelper(hiddenInput);
            });
            observer.observe(hiddenInput, { attributes: true, attributeFilter: ['value'] });
        });
    }

    removePlaceholder(event) {
        event.target.removeAttribute('placeholder');
    }

    toggleHelper(hiddenInput) {
        const helperId = hiddenInput.dataset.helperId;
        const helperElement = document.getElementById(helperId);
        if (helperElement) {
            if (hiddenInput.value) {
                helperElement.classList.remove('block');
            } else {
                helperElement.classList.add('block');
            }
        }
    }
}
