import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import useStore from '@/modules/store';
import { getCustomSelectStyles } from '@/modules/common';

const GenericSingleSelect = ({ id, onChange, required, apiPath, placeholder }) => {
    const [options, setOptions] = useState([]);
    const selects = useStore(state => state.selects);
    const isInvalid = useStore(state => state.selects[id]?.invalid);
    const setSelectValue = useStore(state => state.setSelectValue);

    useEffect(() => {
        fetchOptions().then(() => {
            updateSelectedOptionFromStore(true);
        });
    }, [apiPath]);

    const fetchOptions = async () => {
        try {
            const response = await fetch(`/api/v1/${apiPath}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (Array.isArray(data)) {
                const formattedOptions = data.map(item => ({ value: item.id, label: item.name }));
                setOptions(formattedOptions);
            } else {
                console.error('Invalid data format');
            }
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const updateSelectedOptionFromStore = (initialSetup = false) => {
        const currentSelectValue = selects[id]?.value;
        if (currentSelectValue) {
            const isNewOption = currentSelectValue.isNew;
            const selected = options.find(option => option.value === currentSelectValue.id) || (isNewOption ? {
                value: currentSelectValue.id,
                label: currentSelectValue.name
            } : null);
            if (isNewOption && selected && !options.includes(selected)) {
                setOptions(prevOptions => [...prevOptions, selected]);
            }
            if (selected && !initialSetup) {
                if (selects[id]?.value.id !== selected.value || selects[id]?.value.name !== selected.label) {
                    setSelectValue(id, { id: selected.value, name: selected.label }, required, false);
                }
            }
        }
    };

    useEffect(() => {
        updateSelectedOptionFromStore();
    }, [selects[id]?.value]);

    const handleChange = selectedOption => {
        if (selectedOption) {
            setSelectValue(id, { id: selectedOption.value, name: selectedOption.label }, required, false);
            if (onChange) {
                onChange(selectedOption.value);
            }
        } else {
            setSelectValue(id, null, required, true);
            if (onChange) {
                onChange(null);
            }
        }
    };

    const customStyles = getCustomSelectStyles(required, isInvalid);
    placeholder = placeholder || '请选择...';

    const selectedOption = selects[id]?.value ? options.find(option => option.value === selects[id].value.id) : null;

    return (
        <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            styles={customStyles}
            className="span-sm-gray"
            placeholder={placeholder}
            isClearable={!required}
        />
    );
};

export default GenericSingleSelect;
