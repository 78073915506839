import { Controller } from "@hotwired/stimulus"
import React from "react"
import { createRoot } from "react-dom/client"
import GenericSingleSelect from "@/components/GenericSingleSelect"
import useStore from '@/modules/store'

export default class extends Controller {
    static values = { props: Object }

    connect() {
        this.renderComponent()
    }

    disconnect() {
        if (this.root) {
            this.root.unmount()
        }
    }

    async renderComponent() {
        const props = this.propsValue
        const storeId = props.storeId
        const hiddenId = props.hiddenId
        const hiddenInput = document.getElementById(hiddenId)

        if(storeId && storeId.indexOf("NEW_RECORD") !== -1){
            return;
        }

        const handleOptionChange = (selectedOptionValue) => {
            if (hiddenInput) {
                hiddenInput.value = selectedOptionValue || ''
            }
        }

        this.root = createRoot(this.element)
        this.root.render(
            React.createElement(GenericSingleSelect, {
                id: storeId,
                apiPath: props.apiPath,
                required: props.required,
                placeholder: props.placeholder,
                onChange: handleOptionChange
            })
        )

        if (hiddenInput && hiddenInput.value) {
            const setSelectValue = useStore.getState().setSelectValue
            let selectedId = parseInt(hiddenInput.value, 10)
            if (isNaN(selectedId)) {
                selectedId = hiddenInput.value
            }
            const option = await this.fetchOptionById(selectedId)
            if (option) {
                setSelectValue(storeId, { id: option.id, name: option.name }, props.required)
            }
        }
    }

    async fetchOptionById(id) {
        try {
            const timestamp = new Date().getTime()
            const response = await fetch(`/api/v1/${this.propsValue.apiPath}/${id}?t=${timestamp}`)
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            const data = await response.json()
            if (!data || !data.hasOwnProperty('id') || !data.hasOwnProperty('name')) {
                throw new Error('Invalid data format')
            }

            return { id: data.id, name: data.name }
        } catch (error) {
            console.error('Error fetching option by ID:', error)
            console.error('ID:', id)
            console.error('API Path:', this.propsValue.apiPath)
        }
    }
}