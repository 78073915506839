import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["displayDiv", "formDiv"];

    confirmUpload() {
        this.displayDivTarget.classList.add('hidden');
        this.formDivTarget.classList.remove('hidden');
    }

    cancelUpload() {
        this.displayDivTarget.classList.remove('hidden');
        this.formDivTarget.classList.add('hidden');
    }
}
