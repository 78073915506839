import { Controller } from "@hotwired/stimulus";
import { connectControllers } from "~/modules/common";

export default class extends Controller {
    static targets = ["review", "reviews", "reviewForm", "adoptForm", "directForm"]
    fetchReviewFormContent(event) {
        const opinionId = event.target.dataset.opinionId;
        const url = `/vetting/review_form?id=${opinionId}`;

        fetch(url)
            .then(response => response.text())
            .then(html => {
                this.reviewFormTarget.innerHTML = html;
            })
            .catch(error => console.log(error));
    }

    fetchReviewsModalContent(event) {
        const opinionId = event.target.dataset.opinionId;
        const url = `/vetting/reviews?id=${opinionId}`;

        fetch(url)
            .then(response => response.text())
            .then(html => {
                this.reviewsTarget.innerHTML = html;
            })
            .catch(error => console.log(error));
    }

    fetchDirectFormContent(event) {
        const opinionId = event.target.dataset.opinionId;
        const url = `/vetting/direct_form?id=${opinionId}`;

        fetch(url)
            .then(response => response.text())
            .then(html => {
                this.directFormTarget.innerHTML = html;
                connectControllers(document.body, 'react-single-select');
            })
            .catch(error => console.log(error));
    }

    fetchAdoptFormContent(event) {
        const opinionId = event.target.dataset.opinionId;
        const url = `/vetting/adopt_form?id=${opinionId}`;

        fetch(url)
            .then(response => response.text())
            .then(html => {
                this.adoptFormTarget.innerHTML = html;
            })
            .catch(error => console.log(error));
    }

    updateAdoptVisibilityAndContent (event) {
        const actionType = event.target.value;
        const actionTypeHiddenField = document.getElementById("review-action-type-hidden");
        const submitUnitWrapper = document.getElementById("submit_unit-wrapper");
        const adoptUnitWrapper = document.getElementById("adopt_unit-wrapper");
        const suggestChannelsWrapper = document.getElementById("suggest-channels-wrapper");
        const reviewText = this.reviewTarget;
        let reviewContent = "";

        switch(actionType) {
            case "adopt":
                reviewContent = "得到采纳。";
                adoptUnitWrapper.style.display = "block";
                submitUnitWrapper.style.display = "block";
                suggestChannelsWrapper.style.display = "block";
                connectControllers(adoptUnitWrapper, 'react-multi-select');
                connectControllers(submitUnitWrapper, 'react-multi-select');
                break;
            case "pre_adopt":
                reviewContent = "拟采纳。";
                adoptUnitWrapper.style.display = "block";
                submitUnitWrapper.style.display = "block";
                suggestChannelsWrapper.style.display = "block";
                connectControllers(adoptUnitWrapper, 'react-multi-select');
                connectControllers(submitUnitWrapper, 'react-multi-select');
                break;
            case "archive":
                reviewContent = "未采纳（归档）。";
                submitUnitWrapper.style.display = "none";
                adoptUnitWrapper.style.display = "none";
                suggestChannelsWrapper.style.display = "none";
                document.getElementById("submit_channel_ids_str").value = "";
                document.getElementById("adopt_unit_ids_str").value = "";
                break;
        }
        actionTypeHiddenField.value = actionType;
        reviewText.value = reviewContent;
    }

    updateReviewVisibilityAndContent (event) {
        const actionType = event.target.value;
        const actionTypeHiddenField = document.getElementById("review-action-type-hidden");
        const suggestionChannelWrapper = document.getElementById("suggestion-channel-wrapper");
        const reviewText = this.reviewTarget;
        let reviewContent = "";

        switch(actionType) {
            case "accept":
                reviewContent = "同意接收。";
                suggestionChannelWrapper.style.display = "none";
                break;
            case "pre_accept":
                reviewContent = "拟接收。";
                suggestionChannelWrapper.style.display = "none";
                break;
            case "report":
                reviewContent = "同意报送。";
                suggestionChannelWrapper.style.display = "block";
                connectControllers(suggestionChannelWrapper, 'react-single-select');
                break;
            case "return_to_modify":
                reviewContent = "退回修改。";
                suggestionChannelWrapper.style.display = "none";
                break;
            case "reject":
                reviewContent = "稿件归档。";
                suggestionChannelWrapper.style.display = "none";
                break;
        }
        actionTypeHiddenField.value = actionType;
        reviewText.value = reviewContent;
    }

    updateReview(event) {
        const selectedChannel = event.target.options[event.target.selectedIndex].text;
        let reviewContentParts = this.reviewTarget.value.split("\n");
        if (reviewContentParts.length > 1) {
            reviewContentParts.pop();
        }
        reviewContentParts.push(`建议报送渠道修改为 ${selectedChannel}。`);
        this.reviewTarget.value = reviewContentParts.join("\n");
    }

    closeAdoptFormModal(event) {

    }

    closeReviewFormModal(event) {

    }
}
