import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input", "clearButton"];

    connect() {
        this.toggleClearButton();
    }

    toggleClearButton() {
        if (this.inputTarget.value.length > 0) {
            this.clearButtonTarget.classList.remove("hidden");
        } else {
            this.clearButtonTarget.classList.add("hidden");
        }
    }

    clearSearch() {
        this.inputTarget.value = "";
        this.clearButtonTarget.classList.add("hidden");
        this.inputTarget.form.requestSubmit();
    }
}
